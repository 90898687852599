import SimpleOpeningHours from "../util/simple-opening-hours";
import { distance } from "../util/distance";

export function addPlaceDistances({ places, userPosition }) {
  if (!userPosition) {
    return places;
  }

  return places.map(place => {
    const distanceFromUser = distance(
      userPosition.lat,
      userPosition.long,
      place.Latitude,
      place.Longitude
    );

    return {
      ...place,
      distanceFromUser,
    };
  });
}

export function filterPlaces({
  places,
  currentTags,
  isHidingClosedLocations,
  distanceRestriction,
}) {
  if (
    currentTags.length === 0 &&
    !isHidingClosedLocations &&
    !distanceRestriction
  ) {
    return places;
  }

  return places.filter(place => {
    if (distanceRestriction && place.distanceFromUser > distanceRestriction) {
      return false;
    }

    if (isHidingClosedLocations) {
      //TODO - if isOpen is saved to object, can skip rechecking this in place
      const openingHours = place.hours
        ? new SimpleOpeningHours(place.hours)
        : false;

      const isOpenNow = openingHours && openingHours.isOpenNow();

      if (!isOpenNow) return false;
    }

    return currentTags.every(tag => place.tags.includes(tag));
  });
}
