const slugify = require("slugify");

function merge(prop) {
  return function(acc, obj) {
    return [...obj[prop], ...acc];
  };
}

function countInstances(acc, tag) {
  acc[tag] = acc[tag] ? acc[tag] + 1 : 1;
  return acc;
}

exports.tags = (places, field) => {
  const allTags = places.reduce(merge(field), []);

  const counts = allTags.reduce(countInstances, {});

  const tags = Object.entries(counts)
    .sort(([, countA], [, countB]) => countB - countA)
    .map(([name, count]) => ({
      name,
      count,
      slug: slugify(name, { lower: true }),
    }));
  return [...tags];
};
