import React, { useContext } from "react";
import styled from "styled-components";

import FilterContext from "../context/FilterContext";
import { Tag } from "./Tags";

const TagCount = styled.span`
  background: var(--bg);
  font-size: var(--typeScaleTiny);
  color: white;
  padding: 1px 0px 1px 0px;
  width: 21px;
  border-radius: 2px;
  margin-left: 5px;
  text-align: center;
`;

function TagList({ tags, currentTags = [], tagCounts }) {
  const { setCurrentTags, unselectTag } = useContext(FilterContext);

  return tags.map(tag => {
    const checked = currentTags.includes(tag.name);

    const count = tagCounts ? tagCounts[tag.name] || 0 : tag.count;

    return (
      <Tag
        interactive
        noResults={count === 0}
        currentTag={checked}
        htmlFor={`filter-${tag.name}`}
        key={`filter-${tag.name}`}
      >
        <input
          type="checkbox"
          name="tag"
          id={`filter-${tag.name}`}
          value={tag.name}
          checked={checked}
          onChange={e =>
            checked
              ? unselectTag(e.currentTarget.value)
              : setCurrentTags(e.currentTarget.value)
          }
        />
        {tag.name}
        <TagCount>{count}</TagCount>
      </Tag>
    );
  });
}

export default TagList;
