import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

import SimpleOpeningHours from "../util/simple-opening-hours";

import PlaceLink from "./PlaceLink";
import { Tag, Tags } from "./Tags";

const OpenClosedLabel = styled.span`
  font-family: monospace;
  font-weight: bold;
  padding: 3px 5px;
  border-radius: 5px;
  border: 1px solid white;
  text-transform: uppercase;
  color: white;
  font-size: var(--typeScaleExtraSmall);

  &.open {
    background-color: #00cb0f;
  }

  &.closed {
    background-color: #ff0100;
  }
`;

function OpenClosed({ place }) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    //Forces a render of this component on mount, since it doesn't exist on load
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  const openingHours = place.hours
    ? new SimpleOpeningHours(place.hours)
    : false;

  const isOpenNow = openingHours && openingHours.isOpenNow();

  return (
    <OpenClosedLabel className={isOpenNow ? "open" : "closed"}>
      {isOpenNow ? "Open" : "Closed"}
    </OpenClosedLabel>
  );
}

const placeholderDescription =
  "We're still working on a description for this business.";

export default function Place({ place, currentTags }) {
  const description = place.description || placeholderDescription;

  return (
    <PlaceWrapper>
      <header>
        <h2 className="place_title">
          <Link to={"/info/" + place.slug}>{place.name} ➡</Link>
          {place.distanceFromUser && (
            <span className="distanceFromUser">
              {place.distanceFromUser.toFixed(1) + "km"}
            </span>
          )}
        </h2>
        <PlaceLink place={place}></PlaceLink>
      </header>

      <p>{description}</p>

      <address>
        <OpenClosed place={place} />
        <br />
        <span>
          {place.address}{" "}
          <OutboundLink
            href={
              "https://www.google.com/maps/search/?api=1&query=" +
              encodeURI(place.name)
            }
          >
            (map)
          </OutboundLink>
        </span>
        <br />
        <a href={"tel:1+" + place.phone}>{place.phone}</a>
      </address>

      <Tags>
        {place.tags.map(tag => (
          <Tag
            key={tag}
            as="li"
            currentTag={currentTags.has(tag)}
            small
            textOnly
          >
            {tag}
          </Tag>
        ))}
      </Tags>
    </PlaceWrapper>
  );
}

const PlaceWrapper = styled.div`
  .place_title {
    font-size: var(--typeScaleMedium);
    margin: 0;
  }

  header {
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      text-decoration: none;
    }

    .placeLink {
      text-decoration: none;
      font-family: monospace;
      letter-spacing: 1px;
      font-size: var(--typeScaleExtraSmall);
      max-width: 100%;
    }
  }

  .distanceFromUser {
    margin-left: 10px;
    font-size: var(--typeScaleExtraSmall);
  }

  address {
    font-size: var(--typeScaleSmall);
    margin-bottom: 10px;
  }

  li {
    font-size: var(--typeScaleExtraSmall);
    cursor: Default;
  }
`;
