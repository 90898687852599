import React, { useMemo } from "react";

import { tags } from "../util/stats";

import TagList from "./TagList";
import { Tags } from "./Tags";

function TagMenu({ places, foodTags, serviceTags, currentTags }) {
  const tagCounts = useMemo(() => {
    //TODO Potential optimization, if no filters active, tagCounts can be null and defaults will be used

    return tags(places, "tags").reduce((acc, x) => {
      acc[x.name] = x.count;
      return acc;
    }, {});
  }, [places]);

  return (
    <>
      Services
      <Tags as="div">
        <TagList
          tags={serviceTags}
          tagCounts={tagCounts}
          currentTags={currentTags}
        ></TagList>
      </Tags>
      <p className="delivery-note">
        3rd party delivery services like SkipTheDishes, Doordash, and UberEats
        are expensive for businesses. When the option is available, ordering
        directly supports them the most.
      </p>
      Food Types
      <Tags as="div">
        <TagList
          tags={foodTags}
          tagCounts={tagCounts}
          currentTags={currentTags}
        ></TagList>
      </Tags>
    </>
  );
}

export default TagMenu;
