import React, { useMemo } from "react";

import Place from "../components/Place";

function Places(props) {
  const setTags = useMemo(() => {
    return new Set(props.currentTags);
  }, [props.currentTags]);

  return (
    <div className="placesGrid">
      {props.places.map(place => (
        <Place key={place.id} place={place} currentTags={setTags} />
      ))}
    </div>
  );
}

export default Places;
