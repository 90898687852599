import React, { useContext } from "react";
import styled from "styled-components";

import FilterContext from "../context/FilterContext";

import TagMenu from "./TagMenu";

const Container = styled.section`
  background: var(--bgLight);
  border-radius: 10px;

  .delivery-note {
    font-size: var(--typeScaleSmall);
    margin-bottom: 20px;
    margin-top: 10px;
  }

  [type="radio"] {
    margin-right: 5px;
  }

  .distanceOptions {
    margin-top: 10px;
  }

  @media all and (min-width: 350px) {
    .extraFilters {
      display: flex;
      justify-content: space-between;
    }

    .distanceOptions {
      margin-top: 0;
    }
  }

  @media all and (min-width: 800px) {
    .extraFilters {
      & > * {
        white-space: nowrap;
      }
    }

    .distanceOptions {
      display: flex;
    }

    .radio-wrapper {
      margin-left: 20px;
    }
  }
`;

function Filter({ places }) {
  const {
    tags: foodTags,
    serviceTags,
    currentTags,
    isHidingClosedLocations,
    setHideClosedLocations,
    distanceRestriction,
    setDistanceRestriction,
  } = useContext(FilterContext);

  return (
    <Container>
      <TagMenu
        places={places}
        foodTags={foodTags}
        serviceTags={serviceTags}
        currentTags={currentTags}
      />

      <br />

      <div className="extraFilters">
        <div className="checkbox-wrapper">
          <input
            onChange={e => {
              setHideClosedLocations(e.target.checked);
            }}
            checked={isHidingClosedLocations}
            type="checkbox"
            value="hideClosed"
            id="hideClosed"
          />
          <label htmlFor="hideClosed">Hide Closed Locations</label>
        </div>

        <div className="distanceOptions">
          <div className="radio-wrapper">
            <input
              onChange={() => setDistanceRestriction(0)}
              checked={distanceRestriction === 0}
              type="radio"
              value="0"
              id="distance0"
            />
            <label htmlFor="distance0">Any Distance</label>
          </div>

          <div className="radio-wrapper">
            <input
              onChange={() => setDistanceRestriction(1)}
              checked={distanceRestriction === 1}
              type="radio"
              value="1"
              id="distance1"
            />
            <label htmlFor="distance1">1km</label>
          </div>

          <div className="radio-wrapper">
            <input
              onChange={() => setDistanceRestriction(5)}
              checked={distanceRestriction === 5}
              type="radio"
              value="5"
              id="distance5"
            />
            <label htmlFor="distance5">5km</label>
          </div>

          <div className="radio-wrapper">
            <input
              onChange={() => setDistanceRestriction(10)}
              checked={distanceRestriction === 10}
              type="radio"
              value="10"
              id="distance10"
            />
            <label htmlFor="distance10">10km</label>
          </div>

          <div className="radio-wrapper">
            <input
              onChange={() => setDistanceRestriction(20)}
              checked={distanceRestriction === 20}
              type="radio"
              value="20"
              id="distance20"
            />
            <label htmlFor="distance20">20km</label>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Filter;
